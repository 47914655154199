<template>
  <div class="card" itemscope itemtype="http://schema.org/Product">
    <h3 class="card__heading">
      <span itemprop="name">{{ card.title }}</span>
    </h3>

    <div class="card__inner">
      <header
        class="card__header"
        itemprop="offers"
        itemscope
        itemtype="http://schema.org/Offer"
      >
        <span itemprop="priceCurrency" content="EUR">€</span>
        <span itemprop="price" :content="price">{{ card.acf.prijs }}</span>
      </header>

      <div class="card__body" v-html="card.content"></div>
      <!-- <h4 class="card__list-heading">Begeleidingstraject van 8 maanden</h4>
        <ul class="card__list" itemprop="description">
          <li>2 trainingsessies per week</li>
          <li>1x 60min surfles</li>
          <li>1x 40min fitnesstraining</li>
        </ul> -->

      <!-- <h4 class="card__list-heading">Extra’s</h4>
        <ul class="card__list" itemprop="description">
          <li>Surfles</li>
          <li>Meditatie Training</li>
          <li>Coaching gedragsverandering</li>
          <li>Coaching voedingspatroon</li>
        </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  data: function () {
    return {};
  },
  computed: {
    price() {
      return this.card.acf.prijs.replace(/[,|.|p|/|m|\-|\s]/g, "");
    },
  },
  props: {
    card: Object,
  },
};
</script>
