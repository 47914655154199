<template>
  <div>
    <modal v-if="modalOpen" @close="closeModal">
      <template v-slot:modal__heading>
        <h2 class="text-xl">Absolute contra indicaties</h2>
      </template>
      <template v-slot:modal__body>
        <div class="modal-body modal__promo px-5 pb-5">
          <ul class="list-disc pl-8">
            <li>Koorts (de stofwisseling is dan al verhoogd)</li>
            <li>
              Algemene infectieziekten. Ook bij onschuldig lijkende
              infectieziekten als verkoudheid, griep en keelontsteking
            </li>
            <li>Te veel lichamelijke pijn</li>
            <li>
              Extreme vermoeidheid en uitputting (massage prikkels worden dan
              slecht verwerkt)
            </li>
            <li>Aversie tegen massage</li>
            <li>Kneuzingen</li>
            <li>Slechte algemene gezondheid</li>
            <li>Besmettelijke ziekten en huidaandoeningen</li>
            <li>Reumatische aandoeningen</li>
            <li>Onverklaarbaar (nachtelijk) zweten</li>
            <li>Duizeligheid</li>
            <li>Zwangerschap (de buik mag dan niet worden gemasseerd)</li>
            <li>Huidafwijkingen (melanoom)</li>
            <li>Spataderen</li>
            <li>Vaataandoeningen (bijv. trombose en arteriosclerose)</li>
            <li>Fracturen</li>
          </ul>
          <p class="mt-6">
            Wat hier beschreven staat is slechts een indicatie. Als je denkt dat
            je wel gemasseerd kan en wil worden, overleg dat dan met je
            huisarts.
          </p>
        </div>
      </template>
    </modal>
    <main>
      <Article>
        <template v-slot:swirl>
          <swirl :pageId="483" />
        </template>

        <template v-slot:article__header>
          <ArticleHeader
            :heading="`${gql.data.page.title}`"
            :copy="`${gql.data.page.acf.subtitle}`"
          />
        </template>

        <template v-slot:article__image>
          <visual>
            <img
              width="640"
              height="480"
              :src="gql.data.page.featuredImage.node.sourceUrl"
              :alt="gql.data.page.featuredImage.node.altText"
            />
          </visual>
        </template>

        <template v-slot:content__copy>
          <copy @content-click="openModal" v-html="gql.data.page.content" />
        </template>

        <template v-slot:content__scroll>
          <scroll-to :anchor="'#scroll-to'">
            {{ gql.data.page.acf.scrollto }}
          </scroll-to>
        </template>

        <template v-slot:sidebar>
          <sidebar :copy="sidebar.copy" :links="sidebar.links" />
        </template>
      </Article>

      <Offering :cards="gql.data.smPrices.nodes" />
    </main>
    <Footer>
      <template v-slot:links>
        <links :links="links" />
      </template>
    </Footer>
  </div>
</template>

<script>
import Article from "../components/Article.vue";
import ArticleHeader from "../components/ArticleHeader.vue";
import Visual from "../components/ArticleImage.vue";

import Offering from "../components/Offering.vue";
import Links from "../components/Links.vue";
import Sidebar from "../components/Sidebar.vue";
import Footer from "../components/Footer.vue";
import Copy from "../components/ContentCopy.vue";
import ScrollTo from "../components/ContentScroll.vue";
import Swirl from "../components/Swirl.vue";
import Modal from "../components/Modal.vue";

export default {
  name: "SportMassage",
  data: function () {
    return {
      sidebar: {
        copy: "Wil je meer informatie over sportmassage? Neem contact op via:",
        links: [
          {
            href: "tel:+31657640930",
            label: "+31 6 57 64 09 30",
          },
          {
            href: "mailto:info@fitmark.nl",
            label: "info@fitmark.nl",
          },
        ],
      },
      links: [
        {
          name: "overTrainers",
          copyText: "Benieuwd naar de trainers?",
          ctaText: "Ga naar Over trainers",
        },
      ],
      gql: {
        data: {
          page: {
            content:
              "\n<p>Na sportbeoefening zijn met name de spieren zwaar belast en heeft het lichaam tijd nodig om te herstellen. Bij teveel inspanning en te weinig hersteltijd kan er overbelasting ontstaan. Dat uit zich bijv. in vermoeidheid, slecht slapen, verhoogde rustharstslag, stijfheid en pijn. Bij aanhoudende overbelasting ontstaan uiteindelijk blessures. Daarom is een sportmassage op zijn tijd onmisbaar!</p>\n\n\n\n<p>Sportmassage is er op gericht deze insluipende problematiek te bestrijden en de gezondheid van het lichaam te bevorderen. Het verbetert de bloedcirculatie en het stofwisselingsproces. Hierdoor zullen voedingsstoffen en zuurstof beter getransporteerd worden en afvalstoffen worden afgevoerd. Dit alles brengt het lichaam weer in balans!</p>\n\n\n\n<p>Een sportmassage bij Fitmark gaat als volgt. Mark geeft de sportmassages en met hem maak je een afspraak voor een gratis intake. Tijdens deze intake bespreken we jouw klachten en wensen. Het sportmassagetraject wordt hier volledig op aangepast.</p>\n\n\n\n<p>Let op! Er bestaan omstandigheden waarom een (sport)massage niet mag plaatsvinden. Dit worden contra indicaties genoemd. Wanneer er contra indicaties aanwezig zijn mag er absoluut niet gemasseerd worden. Klik hier voor meer informatie over contra indicaties. </p>\n\n\n\n<p>Een massage kan ook heel geschikt zijn voor de niet-sporter. Het heeft namelijk een ontspannende werking op ons lichaam en bevorderd daarmee ons algehele welbevinden. Daarom biedt Fitmark ook ontspanningsmassages aan. Dit kan ook bij uw thuis of op de werkvloer. Geinteresseerd? Stuur een mail en vraag naar de mogelijkheden.</p>\n",
            title: "Sportmassage",
            featuredImage: {
              node: {
                altText: "",
                sourceUrl:
                  "https://content.fitmark.nl/wp-content/uploads/2022/10/sportmassage-therapeut-wat-1024x640.jpeg",
                __typename: "MediaItem",
              },
              __typename: "NodeWithFeaturedImageToMediaItemConnectionEdge",
            },
            acf: {
              subtitle: "Brengt het lichaam weer in balans",
              scrollto: "Bekijk de verschillende opties",
              __typename: "Page_Acf",
            },
            __typename: "Page",
          },
          smPrices: {
            nodes: [
              {
                title: "Auw! Een blessure",
                content:
                  '\n<ul class="card__list"><li>4 sportmassages</li><li>Ideaal voor mensen met sportblessures</li><li>Traject start met een intake (bij prijs inbegrepen)</li><li>Elke massage duurt 55 minuten</li><li>Per extra behandeling: € 65,-</li><li>Eén sportmassage ook mogelijk: € 70,-</li><li>Fitmark leden krijgen 10% korting</li></ul>\n',
                acf: {
                  prijs: "248,-",
                  __typename: "SmPrice_Acf",
                },
                __typename: "SmPrice",
              },
              {
                title: "Moment voor jezelf",
                content:
                  '\n<ul class="card__list"><li>1 ontspanningsmassage  </li><li>Een moment voor jezelf om heerlijk te ontspannen</li><li>Ook ideaal voor mensen met lichte stressklachten</li><li>Massage duurt 55 minuten</li><li>25 min. massage: € 35,-</li><li>Meerdere massages tegelijk boeken ook mogelijk </li><li>5% korting per extra geboekte massage</li></ul>\n',
                acf: {
                  prijs: "60,-",
                  __typename: "SmPrice_Acf",
                },
                __typename: "SmPrice",
              },
              {
                title: "Massage aan huis",
                content:
                  '\n<ul class="card__list"><li>Heerlijk thuis ontspannen</li><li>Massage duurt 55 min.</li><li>Alle benodigde spullen worden geregeld</li><li>Super leuk om cadeau te geven</li><li>Reiskosten: € 2,50 per kilometer (benzinekosten bij inbegrepen)</li></ul>\n\n\n\n<p></p>\n',
                acf: {
                  prijs: "60,-",
                  __typename: "SmPrice_Acf",
                },
                __typename: "SmPrice",
              },
              {
                title: "Ontspannen werknemers",
                content:
                  '\n<ul class="card__list"><li>Houd je werknemers gezond en ontspannen</li><li>Massage duurt 25 min.</li><li>Minimale afname van 5 werknemers</li><li>Abonnementsvorm is ook mogelijk: 1 x per maand = €30,-</li><li>De prijzen zijn per werknemer</li></ul>\n',
                acf: {
                  prijs: "35,-",
                  __typename: "SmPrice_Acf",
                },
                __typename: "SmPrice",
              },
            ],
            __typename: "RootQueryToSmPriceConnection",
          },
        },
      },
      modalOpen: false,
    };
  },
  methods: {
    openModal(/*event*/) {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
  },
  watch: {
    modalOpen(newValue) {
      this.$emit("set-scroll", newValue);
    },
  },
  components: {
    Article,
    ArticleHeader,
    Visual,
    Sidebar,
    Offering,
    Links,
    Footer,
    Copy,
    ScrollTo,
    Swirl,
    Modal,
  },
};
</script>
