<template>
  <!-- prices  prices--4cols -->
  <section id="scroll-to" :class="className" ref="prices">
    <Card v-for="card in cards" :key="card.id" :card="card"></Card>

    <!-- <div class="prices__pagination  pagination">
      <a href="" class="pagination-item  pagination--active"></a>
      <a href="" class="pagination-item"></a>
      <a href="" class="pagination-item"></a>
      <a href="" class="pagination-item"></a>
    </div> -->
  </section>
</template>

<script>
import Card from "./Card";

export default {
  name: "Prices",
  components: {
    Card,
  },
  computed: {
    className() {
      // return ['prices', `prices-grid-${this.cards.length}cols`]
      return ["offering", `offering--${this.cards.length}cols`];
    },
  },
  props: {
    cards: Array,
  },
  mounted: function () {},
};
</script>
