<template>
  <aside class="sidebar">
    <header class="sidebar__header">
      <h4 class="sidebar__heading">Neem contact op</h4>
      <p class="sidebar__copy">
        {{ copy }}
      </p>
    </header>

    <ul class="sidebar__links">
      <li>
        <a class="content-link block" :href="links[0].href">
          {{ links[0].label }}
          <svg class="content-link__icon content-link__icon--phone">
            <use xlink:href="fa-solid.svg#phone"></use>
          </svg>
        </a>
      </li>
      <li>
        <a class="content-link block" :href="links[1].href">
          {{ links[1].label }}
          <svg class="content-link__icon content-link__icon--envelope">
            <use xlink:href="fa-solid.svg#envelope"></use>
          </svg>
        </a>
      </li>
      <template v-if="links.length > 2">
        <li>
          <a class="content-link block" :href="links[2].href">
            {{ links[2].label }}
            <svg class="content-link__icon content-link__icon--comments">
              <use xlink:href="fa-solid.svg#comments"></use>
            </svg>
          </a>
        </li>
      </template>
    </ul>
  </aside>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    copy: String,
    links: Array,
  },
};
</script>
